<template>
  <section>
    <div tabindex="0"
    @keydown.left="exitFull"
    @keydown.right="exitFull"/>
    <div class="container-fluid" id="divfull" >
      <div id="base" refs="divfull" >
        <div id="bg"></div>
        <div id="bg1" @click="playStop" @dblclick="onFull"></div>
        <div id="header">
          <p>TITULO</p>
        </div>
        <div id="sub-header">
          <p>SUB-TITULO</p>
        </div>
        <div id="principal">
          <vimeo-player ref="playerOne" :video-id="videoID" @ready="onReady" :player-heigth="heightTwo" :options="optionsOne"></vimeo-player>
          <div id="sobras" class="vertical-layout">
        </div>
        </div>
        <fullscreen v-model="fullscreen" id="fullinit">
          <div id="secundario">
              <vimeo-player ref="playerTwo" :video-id="videoIDtWO" @ready="onReady" :options="optionsTwo" style="width: 100%;"></vimeo-player>
          </div>
          <div id="mask" @dblclick="exitFull" @click="playStop">
          </div>
        </fullscreen>
        <div id="controll">
          <button @click="play" v-if="!playVideo" class="btn btn-primary" style="margin:10px">Play</button>
          <button @click="pause" v-else class="btn btn-danger" style="margin:10px">Pausar</button>
          <button v-on:click="onFull()" class="btn btn-success" style="margin:10px">Tela cheia</button>
          <button v-on:click="exitFull()" class="btn btn-success" style="margin:10px">Sair Tela cheia</button>
        </div>
      </div>
    </div>
    <div class="container-fluid">
    </div>
  </section>
</template>
<script>
import { vueVimeoPlayer } from 'vue-vimeo-player'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import CarouselInterval from '@/components/carousel/CarouselInterval.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BFormTextarea } from 'bootstrap-vue'
import CoursesServices from './coursesServices'
import Vimeo from './Vimeo.vue'

export default {
  components: {
  },
  data() {
    return {
      counterExit: 0,
      counter: 0,
      fullscreen: false,
      playVideo: false,
      displaySecondWebcam: false,
      baseStorage: process.env.VUE_APP_BASE_PUBLIC,
      link_watch: null,
      lesson_id: null,
      link_watch_principal: 'https://player.vimeo.com/video/715210971?h=d3d8e3999f&amp;badge=0&amp;muted=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      link_watch_second: 'https://player.vimeo.com/video/715210856?h=d3d8e3999f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      questionsApi: [],
      notesApi: [],
      annotation_text: '',
      question_text: '',
      form: {
        time: '',
        text: ''
      },
      videoID: '715210971',
      videoIDtWO: '715210856',
      heightOne: 200,
      heightTwo: 500,
      optionsOne: {
        muted: false,
        autoplay: false,
        controls: true,
        responsive: true,
      },
      optionsTwo: {
        muted: true,
        autoplay: false,
        responsive: true,
      },
      playerReady: false,
      annotations: [
        {
          id: 1,
          text: 'Essa foi uma anotação feita para teste',
          time: '12:30'
        },
        {
          id: 2,
          text: 'Essa foi outra anotação feita para teste',
          time: '15:30'
        }
      ],
      questions: [
        {
          id: 1,
          user: {
            id: 1,
            name: 'Eduardo Breno',
            photo: `${process.env.VUE_APP_BASE_PUBLIC}/storage/image/1637879374.png`
          },
          time: '12:11',
          text: 'Como fazer determinada coisa?',
          answers: [
            {
              id: 1,
              user: {
                id: 3,
                name: 'Jose de carvalho',
                photo: `${process.env.VUE_APP_BASE_PUBLIC}/storage/image/1637879364.png`
              },
              text: 'Fazendo exatamente como consta no manual X Y Z, levando em consideração o risco X Y Z.'
            }
          ]
        }
      ],
      speakers: [
        {
          photo: null,
          name_badge: 'Teste',
          mini_curriculo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent efficitur urna metus, eu convallis enim sollicitudin quis. Sed semper faucibus suscipit. Morbi eleifend bibendum porttitor. Pellentesque vulputate neque sit amet libero cursus, ac molestie tortor lobortis. Nunc molestie felis interdum neque finibus, vel sagittis enim tempus. In porta feugiat metus id mollis.',
          instagram: 'idjfdif'
        }
      ],
      course: {
        about: {
          short_description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent efficitur urna metus, eu convallis enim sollicitudin quis. Sed semper faucibus suscipit. Morbi eleifend bibendum porttitor. Pellentesque vulputate neque sit amet libero cursus, ac molestie tortor lobortis. Nunc molestie felis interdum neque finibus, vel sagittis enim tempus. In porta feugiat metus id mollis',
          experience_level: 'Todos os niveis',
          quantity_of_class: '515',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent efficitur urna metus, eu convallis enim sollicitudin quis. Sed semper faucibus suscipit. Morbi eleifend bibendum porttitor. Pellentesque vulputate neque sit amet libero cursus, ac molestie tortor lobortis. Nunc molestie felis interdum neque finibus, vel sagittis enim tempus. In porta feugiat metus id mollis Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent efficitur urna metus, eu convallis enim sollicitudin quis. Sed semper faucibus suscipit. Morbi eleifend bibendum porttitor. Pellentesque vulputate neque sit amet libero cursus, ac molestie tortor lobortis. Nunc molestie felis interdum neque finibus, vel sagittis enim tempus. In porta feugiat metus id mollis Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent efficitur urna metus, eu convallis enim sollicitudin quis. Sed semper faucibus suscipit. Morbi eleifend bibendum porttitor. Pellentesque vulputate neque sit amet libero cursus, ac molestie tortor lobortis. Nunc molestie felis interdum neque finibus, vel sagittis enim tempus. In porta feugiat metus id mollis Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent efficitur urna metus, eu convallis enim sollicitudin quis. Sed semper faucibus suscipit. Morbi eleifend bibendum porttitor. Pellentesque vulputate neque sit amet libero cursus, ac molestie tortor lobortis. Nunc molestie felis interdum neque finibus, vel sagittis enim tempus. In porta feugiat metus id mollis',
          languages: [
            {
              id: 0,
              title: 'Português'
            }
          ],
          subtitles: [
            {
              id: 0,
              title: 'Português'
            }
          ]
        },
      },
      curso: {},
      // adicionar mais dados dentro de data
      data: {
        id: '',
        width: 640,
        lesson_id: ''
      }
    }
  },
  computed: {},
  watch: {},
  mounted() {
    CoursesServices().getCourse({ course_slug: this.$route.params.course_slug }).then(api => {
      console.log(api)
      this.curso = api.data.response.course
      this.watch(this.curso.modules[0].lessons[0].link, this.curso.modules[0].lessons[0].id)
    })
    this.sync()
  },
  created() {
    const that = this
    document.addEventListener('keyup', (evt) => {
      console.log(evt)
      if (evt.which === 27) {
        // check if fullscreen or not ...
        console.log('27')
      }
      if (evt.keyCode === 27 || evt.keyCode === 122) {
        console.log('entrou')
        that.exitFull()
        evt.preventDefault()
      }
    })
    document.getElementById('fullinit').addEventListener('keyup', (evt) => {
      console.log(evt)
      if (evt.keyCode === 27 || evt.keyCode === 122) {
        console.log('entrou')
        that.exitFull()
        evt.preventDefault()
      }
    })
  },
  methods: {
    async syncTime() {
      await this.sleep(500)
      const time = await this.$refs.playerOne.player.getCurrentTime()
      this.$refs.playerOne.player.setCurrentTime(time)
      this.$refs.playerTwo.player.setCurrentTime(time)
    },
    async exitFull() {
      const secundario = document.getElementById('secundario')
      const mask = document.getElementById('mask')
      console.log(secundario.classList.length)
      if (mask.classList.value !== 0) {
        secundario.classList.remove('fullScreen')
        mask.classList.remove('fullScreen')
      }
      this.fullscreen = !this.fullscreen
    },
    async onFull() {
      const secundario = document.getElementById('secundario')
      const mask = document.getElementById('mask')
      if (mask.classList.length === 0) {
        secundario.classList.add('fullScreen')
        mask.classList.add('fullScreen')
      }
      this.fullscreen = !this.fullscreen
    },
    toggleApi() {
      this.$fullscreen.toggle()
    },
    /*
    play() {
      const player_second = document.getElementById('second_player')
      const player_principal = document.getElementById('principal_player')
      const player = new Vimeo.Player(player_second)

      player.on('play')
      player_principal.on('play')
    },
    */
    onReady() {
      this.playerReady = true
    },
    sync() {
      const skin = localStorage.getItem('vuexy-skin')
      console.log(skin)
      if (skin === 'dark') {
        if (document.getElementById('sobras')) {
          document.getElementById('sobras').classList.add('dark-layout')
        }
      }
    },
    full() {
      console.log(this.$refs.playerOne)
      this.$refs.playerOne.$parent.full()
      /*
      const div = document.getElementById('secundario')
      if ((document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (div.requestFullScreen) {
          div.requestFullScreen()
        } else if (div.mozRequestFullScreen) {
          div.mozRequestFullScreen()
        } else if (div.webkitRequestFullScreen) {
          div.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
        }
      } else if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
      */
    },
    play() {
      this.$refs.playerOne.play()
      this.$refs.playerTwo.play()
      this.playVideo = true
    },
    pause() {
      this.$refs.playerOne.pause()
      this.$refs.playerTwo.pause()
      this.playVideo = false
    },
    async playStop() {
      const time = await this.$refs.playerOne.player.getCurrentTime()
      this.$refs.playerOne.player.setCurrentTime(time)
      this.$refs.playerTwo.player.setCurrentTime(time)
      if (this.playVideo === false) {
        this.$refs.playerOne.play()
        this.$refs.playerTwo.play()
        this.playVideo = true
      } else {
        this.$refs.playerOne.pause()
        this.$refs.playerTwo.pause()
        this.playVideo = false
      }
    },
    async swape() {
      const time = await this.$refs.playerOne.player.getCurrentTime()
      console.log(time)
      const temp = this.videoID
      this.videoID = this.videoIDtWO
      this.videoIDtWO = temp
      if (this.displaySecondWebcam === false) {
        this.displaySecondWebcam = true
      } else {
        this.displaySecondWebcam = false
      }
      await this.sleep(1000)
      this.$refs.playerOne.play()
      this.$refs.playerTwo.play()
      this.$refs.playerOne.player.setCurrentTime(time)
      this.$refs.playerTwo.player.setCurrentTime(time)
      this.playVideo = true
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, text_title, text) {
      this.$bvToast.toast(text, {
        title: text_title,
        variant,
        solid: true,
      })
    },
    watch(link, id) {
      this.link_watch = `https://player.vimeo.com/video/${link}?h=d3d8e3999f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`
      this.lesson_id = id
      this.data.id = link
      this.data.lesson_id = id
      CoursesServices().getQuestions({ lesson_id: this.lesson_id }).then(api => {
        console.log(api)
        this.questionsApi = api.data.response.questions
      })
      CoursesServices().getNotes({ lesson_id: this.lesson_id }).then(api => {
        console.log(api)
        this.notesApi = api.data.response.notes
      })
    },
    toSendQuestion(question_text) {
      try {
        this.form.question = question_text
        this.form.lesson_id = this.lesson_id
        console.log('Enviando form', this.form.text)
        this.makeToast('success', 'Pergunta enviada com sucesso', 'Em breve nosso instrutor entrarar em contato!')
      } catch {
        console.log('erro ao enviar')
        this.makeToast('success', 'Houver um problema ao enviar a pegunta', 'Pedimos que tente novamente!')
      } finally {
        this.form.text = ''
        this.form.time = ''
        this.question_text = ''
      }
      CoursesServices().postQuestion(this.form).then(api => {
        if (api.data.status === 400) {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'InfoIcon',
              title: api.data.message,
              variant: 'danger',
            }
          })
        } else if (api.data.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: api.data.message,
              icon: 'InfoIcon',
              variant: 'success',
            }
          })
          CoursesServices().getQuestions({ lesson_id: this.lesson_id }).then(tapi => {
            console.log(api)
            this.questionsApi = tapi.data.response.questions
          })
        }
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'InfoIcon',
            title: err.message,
            variant: 'danger',
          }
        })
      })
    },
    toSendAnnotation(annotation_text) {
      this.form.text = annotation_text
      this.form.lesson_id = this.lesson_id
      CoursesServices().postNotes(this.form).then(api => {
        if (api.data.status === 400) {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'InfoIcon',
              title: api.data.message,
              variant: 'danger',
            }
          })
        } else if (api.data.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: api.data.message,
              icon: 'InfoIcon',
              variant: 'success',
            }
          })
          CoursesServices().getNotes({ lesson_id: this.lesson_id }).then(rapi => {
            console.log(rapi)
            this.notesApi = rapi.data.response.notes
            this.annotation_text = ''
          })
        }
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'InfoIcon',
            title: err.message,
            variant: 'danger',
          }
        })
      })
    }
  },
}
</script>

<style scoped>
.collapse-title{
  font-size: 18px !important;
}
iframe{
  position: absolute !important;
  top: -400px !important;
}
#base{
  position: relative;
  max-width: 1599px;
  height: 950px;
}
#bg1 {
  position: absolute;
  max-width: 1599px;
  width: 100%;
  height: 100%;
  background-image: url('../../../../assets/animations/template_vazio_colmeia.png');
  background-repeat: no-repeat;
  background-size:contain;
  background-position:center top;
  z-index: 3;
}
#bg {
  position: absolute;
  max-width: 1599px;
  width: 100%;
  height: 100%;
  background-image: url('../../../../assets/animations/tela.jpg');
  background-repeat: no-repeat;
  background-size:contain;
  background-position:center top;
  z-index: 1;
}
#principal {
  position: absolute;
  width: 38%;
  left: -5%;
  margin-top: 10.9%;
  z-index: 2;
}
#secundario {
  position: absolute;
  width: 65.5%;
  margin-left: 29.0%;
  margin-top: 11.2%;
  z-index: 2;
}
#sobras {
  position: absolute;
  width: 15%;
  background-color: #f8f8f8;
  left: 0%;
  height: 102%;
  margin-top: -57%;
  z-index: 5 !important;
}
#controll{
  position: absolute;
  width: 100%;
  margin-left: 0%;
  margin-top: 56.4%;
  z-index: 4;
}
p, h1, h2, h3, h4{
  font-family: 'Montserrat', sans-serif;
}
#header{
  position: absolute;
  margin-left: 30%;
  margin-top: 4%;
  width: 100%;
  z-index: 3;
  font-size: 300%;
  color: white;
  font-weight: bold;
}
#sub-header{
  position: absolute;
  margin-left: 30.4%;
  margin-top: 8%;
  width: 100%;
  z-index: 3;
  font-size: 200%;
  color: white;
  font-weight: medium;
}
.fullScreen {
    width: 100% !important;
    height: 100%  !important;
    position: absolute  !important;
    top: 0  !important;
    left: 0  !important;
    margin: 0px !important;
}
.dark-layout{
  background-color: #161D31 !important;
}
#mask{
  z-index: 99999999;
}
</style>
